exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-articles-js": () => import("./../../../src/pages/articles.js" /* webpackChunkName: "component---src-pages-articles-js" */),
  "component---src-pages-games-js": () => import("./../../../src/pages/games.js" /* webpackChunkName: "component---src-pages-games-js" */),
  "component---src-pages-gpts-js": () => import("./../../../src/pages/gpts.js" /* webpackChunkName: "component---src-pages-gpts-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-page-2-js": () => import("./../../../src/pages/page-2.js" /* webpackChunkName: "component---src-pages-page-2-js" */),
  "component---src-pages-statistics-js": () => import("./../../../src/pages/statistics.js" /* webpackChunkName: "component---src-pages-statistics-js" */),
  "component---src-pages-using-ssr-js": () => import("./../../../src/pages/using-ssr.js" /* webpackChunkName: "component---src-pages-using-ssr-js" */),
  "component---src-pages-using-typescript-tsx": () => import("./../../../src/pages/using-typescript.tsx" /* webpackChunkName: "component---src-pages-using-typescript-tsx" */),
  "component---src-templates-article-template-js": () => import("./../../../src/templates/article-template.js" /* webpackChunkName: "component---src-templates-article-template-js" */)
}

